import { AuthenticationResult } from "@azure/msal-browser";
import { CATEGORY_API_SERVICE_URL } from "../../apiServiceConst";
import { buildRequest, callApiServiceAsync } from "../../utils/callApiService";

export const fetchCategories = async (authResult: AuthenticationResult, callBack?: Function, reportName?: string, checkRole?: boolean) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		let url = `${CATEGORY_API_SERVICE_URL}?reportName=${reportName ?? ""}`;
		if (checkRole) {
			url += `&checkRole=${checkRole}`;
		}
		const categoriesResult = await callApiServiceAsync(url, reqHeaders);
		if (!categoriesResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(categoriesResult);
		}
		return categoriesResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch categories data.");
	}
};