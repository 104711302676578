import { Box, CircularProgress } from "@mui/material";
export const Loading = () => {
  return <Box sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  }} data-sentry-element="Box" data-sentry-component="Loading" data-sentry-source-file="Loading.tsx">
        <CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="Loading.tsx" />
    </Box>;
};