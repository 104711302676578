import { z } from "zod";
import { AuthenticationResult } from "@azure/msal-browser";
import { toFormState, fromErrorToFormState } from "@lib/transformationFormState";
import { buildRequest, callApiServiceAsync } from "@utils/callApiService";
import { USER_API_SERVICE_URL } from "apiServiceConst";
import { ActionStatus, IInviteEditUserItem, IPageCriteria, IUserItem, UserFilter, UserType } from "@definitions/index";


export const fetchUsers = async (
	authResult: AuthenticationResult,
	callBack: Function,
	filter: UserFilter,
) => {
	try {
		const reqHeaders = await buildRequest(authResult, "POST", filter);
		const usersResult = await callApiServiceAsync(
			USER_API_SERVICE_URL,
			reqHeaders
		);
		if (!usersResult) {
			return;
		}
		callBack(usersResult);
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch users data.");
	}
};

export const fetchAllUsers = async (
	authResult: AuthenticationResult,
	callBack: Function
) => {
	try {
		const reqHeaders = await buildRequest(authResult, "GET");
		const usersResult = await callApiServiceAsync(
			`${USER_API_SERVICE_URL}/all-internal-user`,
			reqHeaders
		);
		if (!usersResult) {
			return;
		}
		callBack(usersResult);
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch users data.");
	}
};

/**
 * Fetch pending users
 * @param callBack callback function
 * @returns
 */
export const fetchPendingUsers = async (
	authResult: AuthenticationResult,
	callBack: Function,
	pendingUserCriteria?: IPageCriteria
) => {
	try {
		const { page, pageSize } = pendingUserCriteria ?? {} as IPageCriteria;
		const reqHeaders = await buildRequest(authResult);
		const pendingUsersResult = await callApiServiceAsync(
			`${USER_API_SERVICE_URL}/pending?page=${page ?? ""}&pageSize=${pageSize ?? ""}`,
			reqHeaders
		);
		if (!pendingUsersResult) {
			return;
		}
		callBack(pendingUsersResult);
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch pending users data.");
	}
};

export const auditLogIn = async (
	authResult: AuthenticationResult,
	userId: string
) => {
	try {
		const reqHeaders = await buildRequest(authResult, "POST");
		await callApiServiceAsync(
			`${USER_API_SERVICE_URL}/audit-login`,
			reqHeaders
		);
	} catch (error) {
		console.error("Error:", error);
		throw new Error(`Failed to user login ${userId}.`);
	}
};

export const fetchGroupsOfAUser = async (
	authResult: AuthenticationResult,
	userId: string,
	callback: Function
) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const groupsResult = await callApiServiceAsync(
			`${USER_API_SERVICE_URL}/${userId}/groups`,
			reqHeaders
		);
		if (!groupsResult) {
			return;
		}
		callback(groupsResult);
	} catch (error) {
		console.error("Error:", error);
		throw new Error(`Failed to fetch user groups of ${userId}.`);
	}
};

const InternalUserSchema = z.object({
	formType: z.string(),
	userId: z.string().nullable(),
	emailAddress: z
		.string()
		.transform((t) => t?.trim())
		.refine((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
			message: "Invalid email address format.",
		})
		.pipe(z.string().min(1, "Email Address is required.")),
	roleIds: z.array(z.string()),
	roleId: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "Role is required.")),
	clientCodes: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "Client is required.")),
});

const ExternalUserSchema = z.object({
	firstName: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "First Name is required.")),
	lastName: z
		.string()
		.transform((t) => t?.trim())
		.pipe(z.string().min(1, "Last Name is required.")),
});

const OptionalNameSchema = z.object({
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
});

const ValidateInternalUser = InternalUserSchema.merge(OptionalNameSchema).omit(
	{}
);
const ValidateExternalUser = InternalUserSchema.merge(ExternalUserSchema).omit(
	{}
);

export async function inviteUser(
	authResult: AuthenticationResult,
	data: IInviteEditUserItem
) {
	try {

		const url = data.type === UserType.Internal
			? `${USER_API_SERVICE_URL}/${data.userId}/roles`
			: `${USER_API_SERVICE_URL}/invite`;
		const reqHeaders = await buildRequest(authResult, "POST", data);
		const userResult = await callApiServiceAsync(url, reqHeaders);
		if (!userResult?.success) {
			return toFormState(
				ActionStatus.FAIL,
				userResult?.messages ?? "Failed to invite new user."
			);
		}
		return toFormState(ActionStatus.SUCCESS, "Invite user successfully.");
	} catch (error) {
		return fromErrorToFormState(error);
	}
}

const EditUserSchema = z.object({
	userId: z.string().min(1, "User Id is required."),
	firstName: z.string().min(1, "First Name is required."),
	lastName: z.string().min(1, "Last Name is required."),
	emailAddress: z
		.string()
		.transform((t) => t?.trim())
		.refine((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), {
			message: "Invalid email address format.",
		})
		.pipe(z.string().min(1, "Email Address is required.")),
	roleIds: z.array(z.number()).min(1, "The user has requested at least one role."),
	removeRoleIds: z.array(z.number()).optional(),
	clientCodes: z.array(z.string()).nullable(),
	tenantId: z.string().min(1, "Tenant Id is required."),
});

const ClientCodesSchema = z.object({
	clientCodes: z.array(z.string()).min(1, "The user has requested at least one client."),
});

const EditExternalUserSchema = EditUserSchema.merge(ClientCodesSchema);

export async function editUser(
	authResult: AuthenticationResult,
	user: IInviteEditUserItem
) {
	try {
		const reqHeaders = await buildRequest(authResult, 'POST', user);
		const roleResult = await callApiServiceAsync(`${USER_API_SERVICE_URL}/${user.userId}/edit`, reqHeaders);
		if (!roleResult?.success) {
			return toFormState(ActionStatus.FAIL, roleResult?.messages ?? "Failed to edit user.");
		}
		return toFormState(ActionStatus.SUCCESS, "Update user successful");
	}
	catch (error) {
		return fromErrorToFormState(error);
	}
}

export async function removeAccessUserAsync(
	authResult: AuthenticationResult,
	user: IUserItem
) {
	try {
		const removeAccessUser = {
			userId: user.userId,
			tenantId: user.tenantId,
		};
		const reqHeaders = await buildRequest(authResult, 'POST', removeAccessUser);
		const roleResult = await callApiServiceAsync(`${USER_API_SERVICE_URL}/remove-access`, reqHeaders);
		if (!roleResult?.success) {
			return toFormState(ActionStatus.FAIL, roleResult?.messages ?? "Failed to remove user's access");
		}
		return toFormState(ActionStatus.SUCCESS, "Remove user's access successful");
	}
	catch (error) {
		return fromErrorToFormState(error);
	}
}