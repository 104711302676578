import { LayoutProps } from "@lib/definitions";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";
import { Loading } from "./Loading/Loading";
const ProtectedResource = ({
  children
}: LayoutProps) => {
  const router = useRouter();
  const {
    inProgress,
    accounts
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    // must check both inProgress, isAuthenticated and accounts.length to sure login successful
    if (inProgress === InteractionStatus.None && !isAuthenticated && accounts.length === 0) {
      router.push("/login");
    }
  }, [inProgress, accounts, isAuthenticated]);
  if (inProgress !== InteractionStatus.None) {
    return <Loading />;
  }
  ;
  return <>{children}</>;
};
export default ProtectedResource;