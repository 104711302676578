"use client";

import { Box, CircularProgress } from "@mui/material";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import { redirect } from "next/navigation";
import { AuthenticatedContext, AuthorizationContext } from "@components/AuthorizationContext";
import ProtectedResource from "@components/ProtectedResource";
import SideNav from "@components/SideNav/SideNav";
import { AdminRole, AllRoles } from "@lib/authorizationRoles";
import { auditLogIn, fetchGroupsOfAUser } from "@lib/users/user-client-service";
import { LayoutProps } from "@lib/definitions";
import { getTokenAccess } from "@utils/callApiService";
import { checkRole, checkRoleAdmin } from "@utils/helpers";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import AppHeader from "@components/Layout/AppHeader";
import AppFooter from "@components/Layout/AppFooter";
import { defaultStyles } from "@definitions/index";
export default function MainLayout({
  children
}: Readonly<LayoutProps>) {
  const {
    instance,
    accounts
  } = useMsal();
  const [sideNavWidth, setSideNavWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const isInitial = useRef(true);
  const [authenticatedContext, setAuthenticatedContext] = useState<AuthenticatedContext>({} as AuthenticatedContext);
  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount && authenticatedContext.userGroups === undefined && isInitial.current) {
      const fetchUserGroups = async () => {
        const apiTokenResult = await getTokenAccess(instance, activeAccount, true);
        if (apiTokenResult === undefined || apiTokenResult === null) {
          return;
        }
        await auditLogIn(apiTokenResult, activeAccount.localAccountId);
        await fetchGroupsOfAUser(apiTokenResult, activeAccount.localAccountId, (res: any) => {
          const isAdminRole = checkRoleAdmin(res, AdminRole);
          setAuthenticatedContext(new AuthenticatedContext(instance, apiTokenResult, activeAccount, res, isAdminRole, activeAccount.tenantId === process.env.NEXT_PUBLIC_AAD_TENANT_ID!, isAdminRole && activeAccount.tenantId === process.env.NEXT_PUBLIC_AAD_TENANT_ID!));
          setIsLoading(false);
        });
      };
      fetchUserGroups();
      isInitial.current = false;
    } else if (activeAccount && authenticatedContext.userGroups) {
      authenticatedContext.refreshToken();
      setIsLoading(false);
    }
  }, [instance, accounts, authenticatedContext]);
  useEffect(() => {
    if (!isLoading && !checkRole(authenticatedContext, AllRoles)) {
      redirect("/unauthorized");
    }
  }, [isLoading, authenticatedContext]);
  return <ProtectedResource data-sentry-element="ProtectedResource" data-sentry-component="MainLayout" data-sentry-source-file="layout.tsx"> {isLoading ? <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%"
    }}>
				<CircularProgress />
			</Box> : <Box sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      minWidth: defaultStyles.minWidth
    }}>
				{authenticatedContext.userGroups.length > 0 && <AuthorizationContext.Provider value={authenticatedContext}>
						<SideNav widthCallbackFn={(w: number) => setSideNavWidth(w)} />
						<Box sx={{
          display: "flex",
          flexDirection: "column",
          width: `calc(100% - ${sideNavWidth}px)`
        }}>
							<Box>
								<AppHeader />
							</Box>
							<Box component='main' sx={{
            flexGrow: 1
          }}>
								<AuthenticatedTemplate>
									{children}
									<AppFooter></AppFooter>
								</AuthenticatedTemplate>
							</Box>
						</Box>
					</AuthorizationContext.Provider>}
			</Box>}
	</ProtectedResource>;
}