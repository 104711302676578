"use client";

import { Box, Divider, Grid, Typography } from "@mui/material";
import { defaultStyles } from "@components/ThemeRegistry/theme";
import { Chat, MailOutline, Phone } from "@mui/icons-material";
const AppFooter = () => {
  return <Box sx={{
    paddingTop: "140px"
  }} data-sentry-element="Box" data-sentry-component="AppFooter" data-sentry-source-file="AppFooter.tsx">
			<Divider sx={{
      borderColor: "#00000026"
    }} data-sentry-element="Divider" data-sentry-source-file="AppFooter.tsx" />
			<Box sx={{
      paddingLeft: defaultStyles.appPaddingLeft,
      paddingRight: defaultStyles.appPaddingLeft,
      paddingTop: "30px",
      paddingBottom: "30px",
      minHeight: "160px",
      maxHeight: "160px",
      height: "160px"
    }} data-sentry-element="Box" data-sentry-source-file="AppFooter.tsx">
				<Grid container spacing={2} columns={16} data-sentry-element="Grid" data-sentry-source-file="AppFooter.tsx">
					<Grid item xs={8} data-sentry-element="Grid" data-sentry-source-file="AppFooter.tsx">
						<Box data-sentry-element="Box" data-sentry-source-file="AppFooter.tsx">
							<Typography sx={{
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "20px"
            }} data-sentry-element="Typography" data-sentry-source-file="AppFooter.tsx">
								Need Help? <br></br> Contact Internal Services
							</Typography>
							<Typography sx={{
              fontWeight: 350,
              fontSize: "13px",
              lineHeight: "20px"
            }} data-sentry-element="Typography" data-sentry-source-file="AppFooter.tsx">
								<MailOutline sx={{
                fontSize: "18px",
                marginBottom: "-4px",
                marginRight: "3px"
              }} data-sentry-element="MailOutline" data-sentry-source-file="AppFooter.tsx" />
								info@cardcompliant.com
							</Typography>
							<Typography sx={{
              fontWeight: 350,
              fontSize: "13px",
              lineHeight: "20px"
            }} data-sentry-element="Typography" data-sentry-source-file="AppFooter.tsx">
								<Phone sx={{
                fontSize: "18px",
                marginBottom: "-4px",
                marginRight: "3px"
              }} data-sentry-element="Phone" data-sentry-source-file="AppFooter.tsx" />
								913.871.7430
							</Typography>
						</Box>

					</Grid>
					<Grid item xs={8} data-sentry-element="Grid" data-sentry-source-file="AppFooter.tsx">
						<Typography sx={{
            fontWeight: 350,
            fontSize: "11px",
            lineHeight: "20px",
            textAlign: "right"
          }} data-sentry-element="Typography" data-sentry-source-file="AppFooter.tsx">
							© 2024 Card Compliant LLC
							<br></br> All Rights Reserved | Privacy Policy
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Box>;
};
export default AppFooter;