import { ListItem, ListItemButton, ListItemText, Collapse, Typography, Divider, Box } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { IMenuItem } from "@definitions/index";
import { colors } from "@components/ThemeRegistry/theme";
import { AuthorizationContext } from "@components/AuthorizationContext";
export const Menu: React.FC<{
  items: IMenuItem[];
  collapse?: boolean;
  onIconHover?: (collapse: boolean) => void;
  isSubmenu?: boolean;
}> = ({
  items,
  collapse,
  onIconHover,
  isSubmenu
}) => {
  const router = useRouter();
  const authenticatedContext = useContext(AuthorizationContext);
  const [open, setOpen] = useState({} as {
    [key: string]: boolean;
  });
  const handleOpenSubmenuClick = (event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLDivElement>, item: IMenuItem) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [item.text]: !prevOpen[item.text]
    }));
  };
  const pathname = usePathname();
  const [activateNav, setActivateNav] = useState("");
  const handleActiveNav = (id: string) => {
    return activateNav.includes(id);
  };
  const get2PartsOfPath = (path: string) => {
    const pathParts = path.split("/");
    let navPath = "";
    if (pathParts.length === 2) {
      navPath = `/${pathParts[1]}`;
    } else if (pathParts.length > 2) {
      navPath = `/${pathParts[1]}/${pathParts[2]}`;
    }
    return navPath;
  };
  const handleClick = (e: any, href: any) => {
    if (href === undefined) {
      return;
    }
    authenticatedContext.setCallbackRedirect(() => router.push(href));
    if (!authenticatedContext.unsavedChange) {
      router.push(href);
    }
    if (authenticatedContext.callbackUnSavedChange) {
      authenticatedContext.callbackUnSavedChange();
    }
    e.preventDefault();
  };
  useEffect(() => {
    const navPath = get2PartsOfPath(pathname);
    let foundActivateNavId = "";
    items.forEach(item => {
      if (item.href && get2PartsOfPath(item.href) === navPath) {
        foundActivateNavId = item.id;
      } else if (item.children) {
        item.children.forEach(child => {
          if (child.href && get2PartsOfPath(child.href) === navPath) {
            foundActivateNavId = child.id;
          }
        });
      }
    });
    setActivateNav(foundActivateNavId);
  }, [pathname, items]);
  const renderNav = (items: IMenuItem[], onIconHover?: (collapse: boolean) => void) => {
    return items.map(item => {
      let props = {};
      if (item.href !== undefined) {
        props = {
          scroll: 'false'
        };
      }
      return item.guardActive && <div key={item.text}>
						{!isSubmenu && <Divider sx={{
          borderColor: colors.divider
        }} />}
						<ListItem disablePadding onClick={event => {
          if (onIconHover !== undefined && !item.children) {
            onIconHover(false);
          }
          if (item.children && !item.href) {
            handleOpenSubmenuClick(event, item);
          }
        }}>
							<ListItemButton {...props} sx={{
            paddingLeft: 0,
            paddingRight: 0
          }}>
								<ListItemText onClick={e => handleClick(e, item.href)} primary={<Typography sx={{
              textDecoration: handleActiveNav(item.id) ? "underline" : "none",
              textUnderlineOffset: 4,
              fontWeight: isSubmenu ? 350 : 500,
              fontSize: "16px",
              lineHeight: "20px"
            }}>
											{item.text}
										</Typography>} />
								<Box sx={{
              height: "24px"
            }} onClick={event => {
              if (item.children && item.href) {
                handleOpenSubmenuClick(event, item);
              }
            }}>
									{item.children && (open[item.text] ? <Remove /> : <Add />)}
								</Box>
							</ListItemButton>
						</ListItem>
						{renderSubNav(item, onIconHover)}
					</div>;
    });
  };
  const renderSubNav = (item: IMenuItem, onIconHover?: (collapse: boolean) => void) => {
    if (!item.children) {
      return null;
    }
    return <Collapse in={open[item.text]} timeout='auto' unmountOnExit data-sentry-element="Collapse" data-sentry-component="renderSubNav" data-sentry-source-file="Menu.tsx">
				<Menu items={item.children} collapse={collapse} onIconHover={onIconHover} isSubmenu={true} data-sentry-element="Menu" data-sentry-source-file="Menu.tsx" />
			</Collapse>;
  };
  return <div data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
			{collapse ? items.map(item => {
      let props = {};
      if (item.href !== undefined) {
        props = {
          scroll: 'false'
        };
      }
      return item.guardActive && <ListItem key={item.text} disablePadding>
								<ListItemButton {...props} onMouseEnter={() => onIconHover && onIconHover(true)} onClick={e => handleClick(e, item.href)}>
								</ListItemButton>
							</ListItem>;
    }) : renderNav(items, onIconHover)}
		</div>;
};